<template>
  <div v-if="language === 'vi'">
    <el-button
      size="small"
      type="primary"
      icon="el-icon-s-promotion"
      :disabled="callingAPI || disabled"
      @click="handleSynchronizeData"
    >Đồng bộ</el-button>
    <synchronize-popup
      v-if="visible"
      :visible="visible"
      :calling="callingAPI"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { updateArtifact } from '@/services/artifact'
import { updatePlace } from '@/services/place'
import { updateTour } from '@/services/tour'
import { updatePost } from '@/services/post'
import SynchronizePopup from '../popups/Synchronize.vue'
import TYPE from '@/data/type'

export default {
  name: 'SynchronizeButton',
  components: {
    SynchronizePopup
  },
  props: {
    disabled: Boolean,
    data: Array,
    type: String
  },
  data() {
    return {
      visible: false,
      callingAPI: false
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    placeType() {
      return TYPE.PLACES.find((p) => p.value === this.type)
    },
    locales() {
      return this.constant.constants.locales
    }
  },
  methods: {
    async handleSynchronizeData() {
      let requests = []
      this.visible = true
      this.callingAPI = true
      if (this.placeType.type === 'tour') {
        requests = this.getUpdateDatas().map((d) => updateTour(d))
      } else if (this.placeType.type === 'post') {
        requests = this.getUpdateDatas().map((d) => updatePost(d))
      } else if (this.type === 'artifact') {
        requests = this.getUpdateDatas().map((d) => updateArtifact(d))
      } else {
        requests = this.getUpdateDatas().map((d) => updatePlace(d))
      }
      await Promise.all(requests).then(() => {
        this.$notify.success({
          title: 'Thông báo !',
          message: 'Đã đồng bộ sữ liệu thành công !'
        })
        this.callingAPI = false
        this.visible = false
      }).catch((error) => {
        this.visible = false
        this.callingAPI = false
        this.$notify.error({
          title: 'Thông báo',
          message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
        })
      })
    },

    getUpdateDatas() {
      const datas = []
      this.data.forEach(d => {
        this.locales.forEach((l) => {
          if (!d.translations.find((t) => t.locale === l.code)) {
            if (d.name) {
              datas.push({ id: d.id, locale: l.code, name: `${d.name} - ${l.code.toUpperCase()}` })
            } else {
              datas.push({ id: d.id, locale: l.code, summary: `${d.summary} - ${l.code.toUpperCase()}` })
            }
          }
        })
      })
      return datas
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
